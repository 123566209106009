import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
//import "firebase/analytics"

const prodConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain:process.env.REACT_APP_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_API_ID,
};

firebase.initializeApp(prodConfig);
export default firebase;
